.LoadingSpinnerWrapper {
  height: 100%;
  min-height: 240px;
}

.LoadingSpinner {
  display: inline-block;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  border: 4px solid transparent;
  border-top-color: currentColor;
  border-radius: 99px;

  animation: LoadingSpinner-transition 0.8s infinite linear;
}

.LoadingSpinner:after {
  content: "";

  display: inherit;
  box-sizing: inherit;
  width: inherit;
  height: inherit;
  border: inherit;
  border-color: currentColor;
  border-radius: inherit;

  opacity: 0.25;
  position: relative;
  top: -4px;
  left: -4px;
}

@keyframes LoadingSpinner-transition {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
