.box {
  display: grid;
  grid-template-columns: repeat(3, minmax(150px, 300px)) repeat(2, max-content) 1fr;
  grid-column-gap: 8px;
  margin-bottom: 16px;
}

.inputNumber {
  width: 100%;
}

.wrap {
  display: grid;
  grid-template-columns: 150px 150px;
  grid-column-gap: 8px;
}

.select {
  width: 100%;
}

.wrapper {
  justify-self: end;
}
