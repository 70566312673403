@import url("./normalize.min.css");

@font-face {
  font-family: "IBM_plex";
  src: local("IBM_plex"), url(./fonts/ibm_plex_sans.ttf) format("truetype");
}

$color-bg: #1a1b21;
$color-main: #1a1b21;
$color-secondary: #24262f;
$color-active: #f2c94c;
$color-active-hover: #f0cd63;

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
}

#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

body,
.ant-layout {
  background-color: $color-bg;
  color: #fff;
  font-family: "IBM_plex";
}

.t_center {
  text-align: center;
}

.documents-list {
  margin-top: 21px;
  padding-left: 1px;

  div {
    padding: 10px;
    background-color: $color-secondary;
    cursor: pointer;

    &:hover {
      background-color: $color-active;
    }
  }

  .active {
    background-color: $color-active;
  }
}

.ant-layout-sider,
.ant-menu.ant-menu-dark {
  background-color: $color-main;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-full {
  flex: 1;
}

.flex-auto {
  flex: 1 1 auto;
  overflow-x: auto;
}

.layout-centered {
  justify-content: center;
  align-items: center;
}

.bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-outline {
  color: white;
  text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;
}

.mt20 {
  margin-top: 20px;
}

.mb20 {
  margin-bottom: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt10 {
  margin-top: 10px;
}

#root .dark_picker {
  input {
    background-color: $color-bg;
    color: #fff;
  }

  i {
    color: #fff;
  }

  .ant-time-picker-clear {
    color: #6b6b6b;
    border-radius: 50%;
  }
}

.ant-statistic-content {
  color: #fff !important;
}

.ant-statistic-title {
  color: #fff;
}

#root,
.ant-layout,
section,
.ant-layout-content {
  height: 100%;
  .active-btn.ant-btn-primary {
    background-color: $color-active;
    border-color: $color-active;
    &:hover {
      background-color: #c3a341;
      border-color: #c3a341;
    }
    // здесь
  }
}

@keyframes slide-right {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.side-menu-toggle {
  color: #fff;
  line-height: 1;
  cursor: pointer;
  transition: 200ms;
  padding: 10px 15px;
  background: #1a1b21;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-right: 5px;
  }
}

.language-button {
  color: #fff;
  line-height: 1;
  cursor: pointer;
  transition: 200ms;
  padding: 10px 15px;
  background: #1a1b21;
  border-radius: 4px;
  border: none;
}

.language-button:active,
.language-button:focus {
  border: #1a1b21;
}

.go-back {
  position: fixed;
  color: #fff;
  line-height: 1;
  width: 30px;
  left: 5px;
  height: 30px;
  top: 5px;
  cursor: pointer;
  transition: 200ms;

  img {
    width: 30px;
    height: 30px;
    top: 5px;
    position: fixed;
    left: 6px;
  }
}

.main-menu {
  animation: slide-right 400ms cubic-bezier(0, 1, 0.5, 1);
  position: fixed;
  overflow-y: scroll !important;
  bottom: 0;
  top: 0;
  left: 0;
  z-index: 999;
}

.logo {
  padding: 5px 5px 10px 5px;
  height: 60px;
  background-color: $color-secondary;
  margin-bottom: 10px;
  cursor: pointer;

  img {
    float: left;
  }

  h3 {
    position: relative;
    top: 5px;
    margin-bottom: 0;
  }

  div {
    margin-left: 57px;
  }
}

.nav-wrap {
  z-index: 2;
  cursor: pointer;
  border-radius: 3px;
  background-color: $color-secondary;
  height: 50px;
  margin: 5px 5px 0 5px;
  position: relative;
  display: flex;
  align-items: center;
  width: 235px;

  img {
    width: 20px;
    border-radius: 3px;
    margin: 10px;
  }

  span {
    font-size: 14px;
    width: 150px;
    line-height: 1.4;
    font-weight: 500;
  }

  &:hover {
    background-color: rgb(132, 140, 146);
  }

  .anticon {
    position: absolute;
    top: 20px;
    right: 15px;
    font-size: 10px;
  }
}

.rotate-90 {
  transform: rotate(90deg);
}

.anticon-right {
  transition: 0.5s all ease-out;
}

.nav-hide {
  max-height: 0 !important;
  position: relative;
}

.nav-children {
  max-height: 1200px;
  padding-top: 5px;
  position: relative;
  overflow: hidden;
  padding-left: 40px;
  transition: 0.8s all ease-in-out;

  &:after {
    content: "";
    height: 100%;
    width: 1px;
    background: #777;
    position: absolute;
    z-index: 1;
    bottom: 20px;
    left: 30px;
  }

  .nav-item {
    width: 195px;
    margin: 5px;
    border-radius: 3px;
    position: relative;
    z-index: 3;
    padding: 5px;
    cursor: pointer;
    background-color: #2b3f5c;

    span {
      font-size: 13px;
      display: block;
    }

    &:hover {
      background-color: $color-bg;
    }

    &:before {
      width: 15px;
      content: "";
      position: absolute;
      z-index: 2;
      background-color: #848c92;
      left: -15px;
      height: 1px;
      top: 50%;
    }
  }
}

#root .sub-children {
  margin-left: 20px !important;
  width: 180px;

  &:before {
    width: 30px;
    left: -30px;
  }
}

.employees {
  h3 {
    margin-bottom: 10px;
  }
}

.enbek_wrap {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  h4 {
    margin-right: auto;
  }

  .str_chart {
    height: 40px;
    width: 25vw;

    .in {
      position: relative;
      background-color: #03a9f4;

      &:after {
        content: "";
        height: 60px;
        background-color: #fff;
        width: 1px;
        right: 0px;
        top: -10px;
        position: absolute;
      }
    }

    .out {
      background-color: #d6781f;
    }

    div {
      height: 100%;
      display: inline-block;
    }
  }

  & + .legend {
    margin-left: 6vw;

    p {
      display: inline-block;
      margin-left: 3vw;

      &:before {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
      }
    }

    .out:before {
      background-color: #d6781f;
    }

    .in:before {
      background-color: #03a9f4;
    }
  }
}

.subsidies {
  .anticon-filter {
    float: right;
    font-size: 16px;
    position: relative;
    cursor: pointer;
    z-index: 12;
  }

  .ant-radio-group {
    text-align: center;
    margin: auto;
    width: 100%;
  }

  .ant-radio-wrapper {
    color: #fff;
  }
}

.stats {
  display: flex;

  .number {
    margin-left: auto;
  }

  .item {
    flex: 1;
  }
}

.subsidies_stats {
  div {
    padding: 4px;
    margin: 15px;
  }

  small {
    display: inline-block;
    font-size: 18px;
  }

  h2 {
    display: inline-block;
  }
}

.group-checkbox {
  .ant-checkbox-wrapper {
    display: block;
    margin-bottom: 10px;
    margin-left: 0;
  }

  .bullet-checkbox {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    left: 25px;
    margin-top: 4px;
  }
}

.compare-checkbox {
  margin-left: 50px;
  margin-top: 50px;
}

.income_expenses {
  .stats {
    h4 {
      margin-bottom: 0;
      font-weight: bold;
    }

    label {
      position: relative;
      top: -4px;
      font-size: 12px;
    }
  }
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ant-layout-sider-children {
  a {
    color: #fff;
  }
}

.slider_wrap {
  display: flex;
  text-align: center;

  .fc-control__slider {
    display: flex;
    flex: 1;
    padding: 0 10px;
  }

  .fc-control__arrows {
    width: 65px;
    text-align: center;
    display: flex;
    align-items: center;
  }
}

.rangeslider-horizontal {
  width: 100%;
}

.fc-controls {
  height: 80vh;
  overflow-x: auto;
}

.pull-right {
  margin-left: 10px;
}

.fc-control__title {
  font-size: 12px;
  padding-top: 10px;
}

.fc-control {
  background-color: #09192b82;
  padding: 2px 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  margin-right: 3px;
}

.fc-controls__heading {
  text-align: center;
}

.nav-wrap + .nav-wrap {
  margin-top: 10px;
}

.left-16 {
  left: -16px;
}

.mh-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.p-10 {
  padding: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.stats_wrap {
  text-align: center;

  span {
    display: block;
    font-size: 14px;
    height: 35px;
  }

  h3 {
    font-weight: bold;
  }
}

.panel_wrapper {
  h4 {
    font-size: 15px;
  }
}

.emergency.panel {
  position: relative;
}

#root .panel.chs {
  margin-top: 10px;
  height: 355px;
  overflow: auto;

  .block {
    &:nth-child(odd) {
      background-color: $color-main;

      .image_wrap {
        background-color: $color-bg;
        padding-right: 5px;
      }
    }

    &:nth-child(even) {
      background-color: $color-secondary;

      .image_wrap {
        background-color: $color-bg;
        padding-right: 5px;
      }
    }
  }

  img {
    width: 20px;
    height: 20px;
  }

  h4 {
    font-size: 10px;
  }

  .title {
    padding: 0;
    margin: 0;
    flex: 1;
    text-align: left;
  }

  .info {
    font-size: 9px;
    padding: 0;
    flex: 1;
    text-align: left;
  }

  .block {
    width: 100%;
    flex: 0 1 auto;
  }
}

.crime-block {
  border: 1px solid #848b84f6;
  padding: 5px;
  margin: 2px;
  margin-bottom: 5px;
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;

  & > h5 {
    width: 89%;
  }

  .item {
    width: 100%;
    flex: 0 1 auto;
    display: flex;
    padding: 1px;

    p {
      font-size: 10px;
      width: 90%;
      margin-bottom: 0;
    }

    span {
      font-size: 10px;
      display: block;
      text-align: right;
      width: 10%;
      padding-right: 10px;
    }

    &:nth-child(odd) {
      background-color: $color-main;
    }

    &:nth-child(even) {
      background-color: $color-secondary;
    }
  }
}

.income_expenses {
  h4 {
    text-align: center;
  }

  .color-orange {
    color: #f4c803;
    text-transform: uppercase;
    font-weight: bold;
  }

  .title {
    font-size: 12px;
  }

  label {
    display: block;
    text-align: center;
    font-size: 10px;
  }
}

.agriculture {
  .stats {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    height: 50vh;

    div {
      width: 80%;
      margin: 10px auto;
    }

    small {
      font-size: 18px;
      display: inline-block;
    }

    h2 {
      display: inline-block;
      font-size: 18px;
    }
  }
}

#root .spin--center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.agr-legends {
  padding: 0 10px;
  height: 47vh;

  & > div {
    position: relative;
  }

  p {
    font-size: 15px;
    text-align: left;
    padding-right: 25px;
    margin-bottom: 16px;
  }

  span {
    position: absolute;
    width: 17px;
    height: 17px;
    right: 0px;
    top: 6px;
  }
}

.paneldc {
  height: 50vh;

  h3 {
    font-size: 15px;
  }

  .info_block {
    margin-bottom: 3px;
    padding: 2px;

    img {
      width: 15px;
      padding: 3px;
    }

    h3 {
      font-size: 12px;
    }

    p {
      font-size: 12px;
    }
  }
}

.dtp-avg-table {
  padding: 17px;

  span {
    display: inline-block;
    font-size: 20px;
  }

  h2 {
    display: inline-block;
    font-size: 20px;
    margin-left: 15px;
  }
}

.crime-dtp {
  h4 {
    font-size: 15px;
    margin-bottom: 2px;
  }

  h5 {
    margin-bottom: 2px;
    padding: 3px 0;
  }
}

.details-table-wrap {
  height: 48vh;
  overflow: auto;
}

.search-input {
  margin-left: 5px;
  margin-top: 5px;
  width: 98%;

  input {
    background: #19375a;
    color: #fff;
  }
}

.event_names {
  height: 25vh;
  overflow: auto;
  margin: 0 10px 10px 10px;
  border: 1px solid #eee;
  padding-right: 5px;
  border-radius: 3px;
}

.ant-tree {
  border-radius: 5px;
  margin: 5px;
  border: 1px solid #9e9e9e;

  .ant-tree-title,
  .anticon-caret-down {
    color: #fff;
    white-space: normal;
  }

  .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: #3366e6 !important;
  }

  .ant-tree-node-content-wrapper {
    height: initial !important;
    width: 90%;
  }

  .ant-tree-node-content-wrapper:hover {
    background-color: #3366e6 !important;
  }

  li {
    border-bottom: 1px solid #797979;
  }

  li:last-child {
    border-bottom: none;
  }
}

.ecology .graph1 {
  display: flex;
  flex-wrap: wrap;

  .item {
    width: 50%;
    border-left: 2px solid #a0a0a0;
    margin-bottom: 10px;
    padding-left: 5px;
    padding-bottom: 5px;

    p {
      margin-bottom: 0;
    }

    h3 {
      margin-bottom: 0;
    }

    small {
      position: relative;
      top: -10px;
      font-size: 9px;
    }
  }
}

.dtp-block {
  border: 1px solid #848b84f6;
  padding: 5px;
  margin: 2px;
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;

  & > h5 {
    width: 89%;
  }

  .item {
    font-size: 10px;
    width: 100%;
    flex: 0 1 auto;
    display: flex;
    padding: 1px;

    p {
      width: 90%;
      margin-bottom: 0;
    }

    span {
      display: block;
      text-align: right;
      width: 10%;
      padding-right: 9px;
    }

    &:nth-child(even) {
      background-color: $color-main;
    }

    &:nth-child(odd) {
      background-color: $color-secondary;
    }
  }
}

.ant-row::before,
.ant-row::after {
  clear: both;
}

.sectionRow,
.sectionCol {
  height: 48vh;
}

.sectionRow {
  box-sizing: border-box;
}

.range_date_wrapper {
  display: flex;
  justify-content: space-around;

  .ant-calendar-picker {
    margin-left: 3px;
    width: 49%;
  }

  .ant-slider {
    width: 100%;
  }
}

// .table_wrap.withScroll {
// p {
//   height: 26px;
//   overflow-y: scroll;
// }
// }

.table_wrap {
  background-color: $color-main;
  padding: 1px;

  .table-percentage {
    background-color: rgba($color: #30be53, $alpha: 0.5);
    padding: 3px;
    border-radius: 3px;
  }

  .ant-table-thead > tr > th {
    background-color: $color-main;
  }

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr {
    padding: 8px;
    color: #fff;
  }

  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background-color: $color-main;
  }

  tr.ant-table-expanded-row,
  tr.ant-table-expanded-row:hover {
    background-color: $color-bg;
  }

  .ant-table-row-expand-icon {
    background-color: $color-bg;
  }

  .t_row {
    background-color: $color-secondary;
    margin: 5px 3px;
    height: 11.5%;
  }

  .column {
    display: inline-block;
    width: 48%;
    padding: 5px;
    max-height: 75px;
    overflow: hidden;

    &:last-child {
      margin-top: 5px;
      margin-right: 5px;
      // background-color: $color-main;
    }
  }

  p {
    margin-bottom: 0;
    font-size: 12px;
    white-space: normal;
    text-overflow: ellipsis;
  }
}

.school_stat {
  padding: 10px;
  display: flex;
  flex-flow: wrap;
  background-color: $color-main;
  margin-left: 15px;

  .block {
    width: 50%;
    text-align: center;
    padding: 15px 10px;

    h2 {
      border-bottom: 2px solid rgb(0, 119, 255);
      width: 100px;
      margin: auto;
      padding-bottom: 5px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    span {
      line-height: 1.3;
      display: block;
      font-size: 16px;
      margin: auto;
    }
  }
}

.pie_stat {
  text-align: center;

  .content {
    height: 20px;
    margin: 0 5px;
    background-color: #80808061;
    padding: 3px;
    border-bottom: 3px solid transparent;
  }

  .number {
    line-height: 1;
    float: left;
  }

  .percent {
    line-height: 1;
    float: right;
  }
}

.business-map {
  .items {
    display: flex;
    margin-bottom: 10px;
  }

  h4.number {
    margin-left: auto;
  }
}

.table_height {
  margin-top: 10px;
  height: 44vh;
  overflow-y: auto;
}

table {
  width: 100%;
}

.stop-list {
  margin-top: -28px;
}

.stop-list .wrap {
  height: 70vh;
  overflow-y: auto;
  margin-left: 5px;

  .item {
    padding: 10px;
    border-bottom: 1px solid #eee;
    background-color: $color-main;

    &:nth-child(odd) {
      background-color: $color-secondary;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.chs {
  display: flex;
  height: 50vh;
  flex-wrap: wrap;
  overflow: auto;
}

#root .dark-pagination-transport {
  background-color: $color-main;
  padding: 10px 5px;

  .ant-pagination-item {
    margin-right: 3px;
  }
}

.gos-number-block {
  background-color: $color-main;
  height: 100%;

  .range_date_wrapper {
    .ant-calendar-picker {
      margin-bottom: 10px;
    }
  }

  .title {
    display: flex;
    padding: 5px 10px;
    background-color: $color-secondary;

    h4 {
      width: 70%;
      margin-bottom: 0;
    }

    span {
      font-size: 12px;
      margin-left: auto;
    }
  }

  .main {
    padding: 10px;

    .item {
      display: flex;
      margin-top: 15px;

      .number {
        width: 30%;
      }

      h4 {
        font-size: 16px;
        width: 70%;
      }
    }

    .filter {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .side {
        width: 70%;

        .ant-select.ant-select-enabled {
          margin-left: 0;
        }

        .ant-input-search {
          margin-left: 0 !important;
        }
      }

      h4 {
        width: 30%;
      }

      .ant-calendar-picker {
        width: 120px;
      }
    }
  }
}

.ant-calendar-picker-input.ant-input {
  background-color: $color-bg;
  color: #fff;
}

.ol-popup {
  position: absolute;
  background-color: $color-secondary;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 5px;
  bottom: 12px;
  left: -50px;
  min-width: 280px;
}

.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: $color-secondary;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup:before {
  border-top-color: $color-secondary;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}

.ol-popup-closer:after {
  content: "✖";
  color: #fff;
}

.popup-content {
  font-size: 11px;
}

#root .dark_table {
  .ant-table-tbody {
    background-color: $color-bg;
    &:hover {
      background-color: $color-main;
    }
    tr.ant-table-placeholder:hover > td {
      color: #fff !important;
      background-color: $color-main;
    }

    .ant-empty {
      color: #fff;
    }
  }
  .ant-table-thead > tr > th {
    color: #fff;
    background-color: $color-secondary;
  }

  .ant-table {
    color: #fff;
  }

  .ant-table-row:hover > td {
    background-color: $color-main;
    border-bottom: 1px solid #716a6a;
  }

  .ant-table-header tr th {
    background-color: #152f4e;
    color: #fff;
  }

  .ant-pagination-item-link {
    color: #fff;
    background: #2a2c36;
  }

  .ant-pagination-item {
    a {
      color: #fff;
    }

    background: #24262f;

    &.ant-pagination-item-active {
      border-color: $color-active;
      background: $color-active;

      a {
        color: #000;
      }
    }
  }

  .ant-pagination-item-ellipsis {
    color: #fff;
  }
}

img {
  color: #fff;
}

.stat_items {
  margin: 10px;

  .item {
    display: flex;

    h4 {
      width: 80%;
    }
  }
}

.info {
  .item-width {
    display: flex;

    h3 {
      width: 80%;
    }
  }

  .green {
    color: #1e9e1a;
  }
}

.chs .block {
  display: flex;
  flex: 0 33.333333333%;
  text-align: center;
  border-left: 10px solid #fff;
  padding-bottom: 1px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    height: 2px;
    background-color: rgb(100, 100, 100);
    width: 100%;
    bottom: 0px;
  }

  .image_wrap {
    display: flex;
    align-items: center;
    padding-left: 5px;
  }

  .title {
    flex: 1;
    margin: 0 5px;
    padding: 0 5px;
    border-right: 1px solid rgb(100, 100, 100);
    align-items: center;
    display: flex;
    text-align: center;
    border-left: 1px solid rgb(100, 100, 100);

    h4 {
      width: 100%;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .info {
    flex: 2;
    padding-right: 5px;
    max-height: 82px;
    overflow: auto;
    margin: auto 0;
  }

  img {
    background-color: #fff;
    width: 48px;
    height: 48px;
  }
}

.ant-statistic {
  text-align: center;
  color: #fff;
}

.ant-calendar-picker-icon {
  color: #fff;
}

.ant-checkbox + span {
  color: #fff;
}

.ant-calendar-picker-input.ant-input-sm {
  height: 32px;
  background: #19375a;
  color: #fff;
}

.dark-pagination-appeals {
  .ant-pagination.mini .ant-pagination-prev {
    margin: 3px;
  }

  .ant-pagination.mini .ant-pagination-item {
    margin-right: 3px;
  }
}

.clickable {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.curricular-activities {
  .stat {
    text-align: center;
  }
}

.event_details {
  padding: 25px;
  border: 1px solid;
  height: 26.25vh;
  background-color: $color-main;

  overflow: auto;

  h4 {
    text-align: center;
  }
}

#hidden {
  display: none;
}

:checked + #hidden {
  display: block;
}

.calendar {
  .day_week {
    background-color: $color-secondary;
  }

  .days {
    text-align: right;
    font-size: 20px;
    border-bottom: 1px solid $color-main;

    & > span {
      display: block;
      padding: 5px;
    }
  }

  & > * {
    flex: 1 0 14.28%;
    text-align: center;
    border-right: 1px solid $color-main;
    text-transform: uppercase;

    &:nth-child(7n) {
      border-right: none;
    }
  }

  .event {
    width: 88px;
    height: 40px;
    overflow: hidden;
  }

  .content {
    position: relative;
    cursor: pointer;

    span {
      min-height: 40px;
      display: block;
      font-size: 11px;
      text-transform: initial;
      text-align: center;
      line-height: 1.7;
      padding: 1px;
    }

    .green {
      border-left: 3px solid #10f508;
      background-color: #11f00a63;
    }

    .red {
      border-left: 3px solid #f51c08;
      background-color: #f0120a63;
    }

    .grey {
      border-left: 3px solid grey;
      background-color: rgb(71, 71, 71);
    }
  }
}

.processing span {
  font-weight: bold;
}

.modal_preview {
  width: 980px !important;
}

.ant-modal {
  width: 1700px !important;

  .ant-modal-body {
    min-height: 300px;
  }
}

.ant-modal-header {
  background-color: $color-main;
  border-bottom-color: $color-bg;
}

.ant-modal-title {
  color: #fff;
}

.ant-modal-body {
  background-color: $color-bg;
}

.ant-modal-close-x {
  color: #fff;
}

.ant-select-selection {
  color: #fff;
  background-color: $color-bg;
}

.ant-select-selection .ant-select-arrow-icon {
  color: #fff;
}

.Table_table__2NaQO {
  color: #fff;
}

.custom-modal-table-wrapper {
  color: white;
  max-height: 65vh;
  overflow-y: auto;
}

.tableModal {
  top: 5%;
  width: 95vw !important;
  height: 90vh !important;

  .ant-modal-content {
    height: 100% !important;
  }

  .ant-modal-body {
    height: 100% !important;
  }
}

.midModal {
  width: 95vw !important;
  top: 5%;
  height: 85vh;

  .ant-modal-body {
    padding: 5px;
  }

  .ant-modal-content {
    height: 100%;
    background-color: initial;
  }

  .ant-modal-body {
    height: 100% !important;
    padding-bottom: 20px;
  }

  .table_height {
    height: 100%;
  }
}

.midModal.spec_width {
  width: 33% !important;
  height: 49vh;
  top: 0%;
}

.appeals_modal {
  // width: 420px !important;
  // top: 5%;
  // height: 85vh;

  * {
    background: #040f1d !important;
    color: white !important;
  }

  // tr {
  //   cursor: pointer;
  // }

  // text-align: center;
  // margin-right: 20px;
  // .ant-modal-body {
  //   padding: 5px;
  // }

  .ant-modal-content {
    height: 100%;
    background-color: initial;
  }

  // .ant-modal-body {
  //   padding-bottom: 20px;
  // }

  // .table_height {
  //   height: 100%;
  // }
}
.ddo_modal {
  * {
    background: #040f1d !important;
    color: white !important;
  }
}

// .appeals_modal.spec_width {
//   width: 33% !important;
//   height: 49vh;
//   top: 0%;
// }

// .appeals_modal.spec_width.five {
//   width: 33% !important;
//   height: 48vh;
//   top: 49%;
// }

// .appeals_modal.edds102 {
//   height: 56%;
// }

.compareZone {
  h3 {
    margin-bottom: 10px;
  }
}

.tourism h3 {
  margin-bottom: 0;
}

.school_stats {
  h2 {
    float: right;
    margin: 10px 0;
  }
}

.attendance_stats {
  display: block;
  font-size: 16px;
  padding: 6px 20px;
  text-align: left;

  p {
    display: inline-block;
    font-size: 16px;
  }

  small {
    font-size: 16px;
  }

  h2 {
    display: inline-block;
    margin: 0;
    font-size: 17px;
  }
}

.filter_3 {
  position: absolute;
  z-index: 11;
  width: 33.33333%;
  right: 4px;
  height: 50vh;
  background: #19375a;

  div {
    width: 100%;
    text-align: center;
  }
}

.filter_4 {
  position: absolute;
  margin-right: 10px;
  margin-top: 0px;
  z-index: 11;
  width: 25%;
  right: 0px;
  height: 50vh;
  background: #122842;
  border: 1px solid #0a1625;
  padding: 10px;
  border-radius: 15px;

  div {
    width: 100%;
    text-align: center;
  }
}

.ant-layout-sider-children > a {
  margin-bottom: 10px;
  display: block;
}

.indicators {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  .indicator_item {
    margin: 5px;
    flex: 0 0 calc(33.3% - 10px);
    padding: 10px 5px;
    background: $color-main;
  }

  .table_item {
    margin: 1px;
    flex: 1 0 41%;
    background: #0b1b2d;
    padding: 10px 5px;
  }

  h4 {
    height: 55px;
  }
}

.ant-result {
  margin: auto;
  background-color: $color-bg;

  .ant-result-title {
    color: #fff;
  }
}

.dark_input {
  input {
    background-color: $color-bg;
    color: #fff;
  }

  i {
    color: #fff;
  }
}

.curricular-activities .title-block {
  margin-bottom: 20px;
}

.table_content {
  overflow: auto;
}

.main_block h3 {
  margin-top: 20px;
}

.deadlines {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .rows {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > * {
      flex: 0 45%;
      margin-bottom: 15px;
    }

    p {
      white-space: normal;
      line-height: 1;
      font-size: 12px;
    }

    span {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: block;
    }

    .red {
      background-color: red;
    }

    .white {
      background-color: white;
    }

    .green {
      background-color: green;
    }

    .yellow {
      background-color: yellow;
    }
  }
}

.general-info {
  padding-top: 6vh;
}

.height_text {
  h4 {
    height: 40px;
  }
}

.land_item {
  height: 35px;
  background-color: #bbb;
  margin-bottom: 10px;

  p {
    line-height: 1;
    padding: 5px;
    margin-bottom: 0;
    width: 60%;
    float: right;
    text-align: right;
    color: #000;
  }

  span {
    color: #000;
    padding-top: 8px;
    padding-left: 3px;
    display: block;
    text-align: center;
    height: 100%;
  }
}

.main_stats {
  text-align: center;
  padding: 15px 10px;
  border: 1px solid #787878;

  span {
    font-size: 12px;
  }

  h3 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.main_stats_2 {
  margin-top: 10px;
  padding: 15px 10px;
  text-align: center;
  border: 1px solid #787878;

  h2 {
    margin-bottom: 0;
  }
}

.preschool {
  .stats {
    text-align: center;

    small {
      line-height: 1;
      display: block;
      margin: 5px 0 15px;
      height: 25px;
      font-size: 15px;
    }

    h2 {
      margin-bottom: 0;
    }
  }
}

.mb-2 {
  margin-bottom: 2rem;
}

.ant-select-selection__clear {
  border-radius: 50%;
}

.nav-stack {
  margin-bottom: 15px;

  h2 {
    display: inline;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    font-size: 16px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  i {
    margin: 0 10px;
  }
}

.emphasize {
  font-weight: bold;
  cursor: pointer;
}

.modal_preview {
  top: 20px;
}

.grid {
  height: 400px;
}

.modal_preview {
  .ant-modal-body {
    position: relative;
  }

  .anticon-left-circle {
    position: absolute;
    top: 50%;
    left: 10px;
    color: red;
    font-size: 35px;
  }

  .anticon-right-circle {
    position: absolute;
    top: 50%;
    right: 10px;
    color: red;
    font-size: 35px;
  }
}

.files_grid {
  position: relative;
  margin-top: 10px;

  .anticon-left-circle {
    position: absolute;
    left: 20px;
    z-index: 10;
    top: 7vh;
    color: #868686;
  }

  .anticon-right-circle {
    position: absolute;
    right: 20px;
    color: #868686;
    top: 7vh;
    z-index: 10;
  }

  img {
    padding: 5px 5px 5px 0;
  }

  i {
    font-size: 36px;
    color: #fff;
    margin: 20vh auto 20px;
    display: block;
  }

  .ant-carousel .slick-dots {
    bottom: -10px;
  }

  .slick-dots {
    &:hover li button {
      height: 8px;
    }
  }
}

.grid {
  img {
    width: 100%;
    height: 400px;
    object-fit: scale-down;
  }

  span {
    text-align: center;
    display: block;
    color: #fff;
  }
}

.bar_wrapper {
  display: flex;
  height: 40vh;
  overflow-y: auto;

  .bar_row {
    display: block;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    padding: 0 15px;
  }

  .bar-container {
    flex-grow: 1;
    display: inline-block;
    width: 72%;
  }

  .bar-legend {
    margin-left: 0.5rem;
    display: inline-block;

    .legend-item {
      display: flex;
      margin-bottom: 0.5rem;
      align-items: center;

      .legend-color {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        margin-right: 4px;
      }
    }
  }

  .bar {
    display: inline-block;
    width: 300px;
    margin: 5px;
    white-space: nowrap;
    font-size: 16px;
    text-align: center;

    .green {
      background-color: #388e3c;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      display: inline-block;
    }

    .blue {
      background-color: #0083c0;
      display: inline-block;
    }

    .yellow {
      background-color: #fbc02d;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      display: inline-block;
    }

    .bar-point {
      position: relative;
      min-width: 50px;

      + .bar-point:before {
        content: "";
        box-shadow: 0 0 4px 1px rgba(35, 35, 35, 0.75);
        display: inline-block;
        position: absolute;
        left: -2px;
        top: -1px;
        bottom: -1px;
        width: 5px;
        background-color: #eee;
      }

      &:first-child {
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
      }

      &:last-child {
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
  }

  .bar-full {
    min-width: 55px;
    width: 100%;
  }

  .title {
    flex: 1;
    font-size: 12px;
  }

  .total {
    width: 70px;
  }
}

.tableStyled {
  thead tr {
    color: #0ea6ef;
    font-weight: normal;
    background: #173252;
  }

  th,
  td {
    line-height: 1;
    color: #fff;
    padding: 8px;
  }

  tbody tr td {
    min-width: 120px;
  }

  tbody tr:nth-child(odd) {
    background: #234870;
  }

  tbody tr:nth-child(even) {
    background: #1b3b5e;
  }

  .clickable {
    td:first-child {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.orange {
  color: rgb(255, 166, 0);
}

.map_legend {
  margin-left: 35px;

  .circle {
    position: relative;

    &:before {
      position: absolute;
      content: "";
      width: 15px;
      height: 15px;
      top: 5px;
      left: 1px;
      background: #fff;
      border-radius: 50%;
    }
  }

  padding: 80px 30px 3px 3px;

  small {
    display: inline-block;
    width: 40%;
    font-size: 16px;
    padding: 3px 10px 3px 3px;
  }

  div {
    display: inline-block;
    width: 100%;
    font-size: 18px;
    padding: 3px 10px 3px 3px;
  }

  p {
    display: inline-block;
    vertical-align: top;
    color: white !important;
    font-size: 18px;
  }

  h2 {
    display: inline-block;
    vertical-align: top;
  }

  .red:before {
    background-color: red;
  }

  .grey:before {
    background-color: grey;
  }

  .orange:before {
    background-color: orange;
  }

  p {
    padding-left: 25px;
  }
}

.info_block {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  align-items: center;
  background-color: $color-main;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 10px;

  img {
    width: 25px;
    margin-right: 10px;
    object-fit: scale-down;
  }

  h3 {
    width: 200px;
    font-size: 14px;
    margin-bottom: 0;
  }

  p {
    padding-left: 10px;
    margin-bottom: 0;
    flex: 1;
    border-left: 1px solid #fff;
  }
}

.ant-input-search {
  margin: 10px 0;
}

.search-passport {
  input {
    background-color: $color-bg;
    color: #fff;
  }
}

.legend_wrap {
  margin-bottom: 10px;

  span {
    margin-left: 10px;
    line-height: 2;
  }
}

.food_item {
  cursor: pointer;

  &:hover img {
    transform: translate(0, -10px);
  }

  .top {
    display: block;
    text-align: center;
    justify-content: space-evenly;
    align-items: center;
    background: $color-secondary;
    padding: 5px 0;
  }

  .image_wrap {
    padding: 0;
    padding-left: 0;
    width: 65px;
    margin: 0 auto;
    display: block;

    img {
      transition: 0.3s ease-in-out 0.2s;
      height: 30px;
      width: 30px;
      object-fit: scale-down;
      display: block;
      margin: auto;
    }
  }

  h3 {
    font-size: 16px;
    margin-bottom: 0;
  }

  .text_wrap {
    padding: 5px;
    background: #359ab9;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    height: 50px;
    overflow: hidden;
    justify-content: center;
  }

  p {
    margin-bottom: 0;
  }
}

.birth-title {
  background: #2196f3;
  width: 200px;
  margin: auto;
  padding: 5px;
  border-radius: 5px;
}

.birth-block {
  border: 1px solid rgb(104, 104, 104);
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
  border-radius: 5px;

  & > * {
    width: 50%;
  }

  h4 {
    margin-bottom: 0;
    text-align: center;
    color: #000;
  }

  .coof {
    background-color: #68caf3;
  }

  .population {
    background-color: #ffb5b1;
  }
}

.birth_rate {
  h3 {
    margin-bottom: 10px;
  }

  .img-wrap {
    padding: 5px 20px 20px 20px;
    width: 180px;
    margin: auto;
  }
}

.baby_item {
  position: relative;
  height: 65px;
  width: 80%;
  margin: 30px auto;

  .circle {
    position: absolute;
    background: #03a9f4;
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 3px solid #fff;
    font-size: 22px;
    top: -5px;
  }

  .block {
    padding-left: 50px;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #03a9f4;
    border-radius: 10px;
    height: 85%;
    margin-left: 10px;
  }
}

.video-monitoring .iframe {
  height: 45vh;
  display: block;
  width: 50%;
}

.video_item {
  background-color: $color-secondary;
  padding: 7px 5px;
  margin: 0 5px 7px 0;
  cursor: pointer;
  transition: 0.5s all;

  &:hover {
    background-color: $color-active;
  }

  p {
    text-align: center;
    margin-bottom: 0;
  }
}

.video_item.active {
  background-color: $color-active;
}

.mt50 {
  margin-top: 50px;
}

.wrap {
  .volume_item {
    .bar {
      height: 20px;
    }

    span {
      height: 20px;
      display: inline-block;
    }
  }
}

.energy-stats {
  display: flex;
  flex-wrap: wrap;

  .height {
    height: 50px !important;
  }

  .item {
    text-align: center;
    width: 50%;
    position: relative;
    height: 85px;
  }

  .oval {
    border-radius: 50%;
    position: absolute;
    border: 4px solid #fff;
    padding: 10px;
    display: flex;
    width: 100px;
    top: -4px;
    height: 60px;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    z-index: 1;
    justify-content: center;
  }

  .block {
    position: absolute;
    border-radius: 10px;
    width: 85%;
    right: 5%;
    padding: 8px;
    padding-left: 65px;
    height: 50px;
    top: 2px;

    h4 {
      margin-bottom: 0;
      line-height: 1.2;
      font-size: 15px;
      text-align: right;
    }
  }
}

.gov-wrapper {
  height: 50vh;
  max-height: 50vh;

  select {
    margin-right: 10px;
  }

  h4 {
    text-align: center;
  }

  .statCard {
    width: 33%;
    height: 125px;
    display: block;
    text-align: center;
    float: left;
    padding: 8px;
  }

  .radio {
    display: inline-block;
    margin-left: 15px;

    input {
      margin-right: 10px;
    }
  }
}

table#table_row_col_span {
  border: hidden;
  border-color: #15537b;
  display: block;
  overflow: scroll;
}

table#table_row_col_span tr > th {
  text-align: center;
  padding: 5px;
  color: white;
  background: #13324e;
}

table#table_row_col_span tr > td > p {
  text-align: center;
}

.procTable {
  tr > th {
    background: transparent;
  }

  div {
    background: #19375a;
    color: #fff;
  }

  tbody {
    background: #19375a;
  }

  *:hover {
    tr,
    td {
      background: unset !important;
    }
  }
}

.marginTop {
  margin-top: 20px;
}

table#table_row_col_span {
  border: hidden;
  border-color: #15537b;
  display: block;
  overflow: scroll;
}

table#table_row_col_span tr > th {
  text-align: center;
  padding: 5px;
  color: white;
  background: #13324e;
}

table#table_row_col_span tr > td > p {
  text-align: center;
}

.half-screen-table {
  margin-top: 28px;
  height: auto;
  font-size: 14px;
  font-weight: 500;

  th {
    padding: 8px 12px;
  }
}

.help-tip:hover p {
  display: block;
  transform-origin: 100% 0%;

  -webkit-animation: fadeIn 0.3s ease-in-out;
  animation: fadeIn 0.3s ease-in-out;
}

.help-tip {
  display: none;
  text-align: left;
  background-color: #19375a;
  padding: 20px;
  width: 300px;
  top: 35px;
  z-index: 99999999999;
  position: absolute;
  border-radius: 3px;
  box-shadow: 3px 3px 3px 4px rgba(0, 0, 0, 0.2);
  right: -4px;
  color: #fff;
  font-size: 13px;
  line-height: 1.4;
}

.asp-hover:hover + .help-tip {
  display: block;
}

.asp {
  h3 {
    margin-bottom: 0px;
  }
}

@media (min-width: 1900px) {
  .nav-wrap img {
    width: 30px;
  }
  .nav-wrap {
    height: 65px;

    span {
      font-size: 17px;
      line-height: 1.3;
    }

    .anticon {
      top: 30px;
    }
  }
  .nav-children .nav-item span {
    font-size: 15px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 22px;
    margin-bottom: 20px;
  }

  h4 {
    font-size: 17px;
  }

  .ant-radio-button-wrapper span {
    font-size: 16px;
  }

  .ant-select-selection__rendered .ant-select-selection__placeholder {
    font-size: 16px;
  }
  .ant-calendar-picker-input.ant-input.ant-input-sm {
    font-size: 18px;
  }
  .table_wrap p {
    font-size: 16px;
  }
  .map_legend {
    p {
      font-size: 18px;
    }
  }
  .map_legend .circle:before {
    top: 8px;
  }

  .preschool .stats small {
    font-size: 17px;
  }

  .processing {
    .info_block {
      h3 {
        font-size: 17px;
      }

      p {
        font-size: 18px;
      }
    }
  }

  .panel_wrapper {
    padding: 5px;

    h3 {
      font-size: 20px;
      margin-bottom: 10px;
    }

    .filters {
      display: flex;
    }

    .height {
      height: 45.5vh;
    }

    .stats {
      padding: 20px 50px;

      .inline {
        display: flex;
        width: 100%;

        h4 {
          margin-left: auto;
          font-size: 22px;
          width: 40px;
        }

        h3 {
          font-size: 19px;
        }
      }
    }
  }
  .filter-flex {
    display: flex;

    & > * {
      flex: 1;
      margin: 5px;
    }
  }
}

.filter-block {
  display: block;
  margin-top: 25px;
}

@media (min-width: 1700px) {
  .slider_wrap .fc-control__arrows {
    width: 100px;
  }
}

.pvtRows {
  width: 200px;
}

svg {
  g[filter^='url("#filter-id-'][aria-labelledby*="-title"] {
    display: none;
  }
}

.filterButton {
  display: block;
  position: fixed;
  right: 16px;
  top: 5px;
  height: 32px;
  width: 32px;
  background: transparent;
  border: none;
  z-index: 99999;
  font-size: 200%;

  &.bottom {
    left: 4px;
    top: unset;
    right: unset;
    bottom: 4px;
  }
}

.filterButton.unset {
  display: block;
  position: initial;
}

.half-screen-height {
  height: 50vh !important;
  overflow: hidden;
  padding: 0;
  margin: 0;
  //margin-left: 50px;
}

.third-screen-height {
  height: 30vh;
  padding: 0;
  margin: 0;
}

.stats-table {
  td,
  th {
    padding: 1rem 2rem;
  }
}

.stats-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
  padding: 2rem;

  .stats-card {
    margin: 0.25rem;
  }

  .stats-name {
    font-weight: lighter;
  }

  .stats-value {
    font-weight: bold;
    font-size: 125%;
  }
}

.attendance_stats_title {
  float: right;
}

.corner-filter-block {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(20, 20, 20, 0.5);

  .ant-form-item-label label {
    color: white;
  }

  > div {
    position: absolute;
    background-color: #1b3b5e;
    border-radius: 10px;
    right: 80px;
    top: 8px;
    width: 400px;
    padding: 20px;
  }

  &.bottom > div {
    right: unset;
    top: unset;
    bottom: 40px;
    left: 8px;
  }
}

.col-vert-center {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.half-screen-center {
  // За изменение глобального стиля ради одного элемента буду колотить по рукам
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > * {
    margin: 1rem 0.25rem;
  }
}

.full-width {
  width: 100%;
}

.chartGrid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-rows: 50vh;

  > * {
    height: 100%;
  }
}

.table-scroll {
  margin-top: 10px;
  display: block;
  // overflow: auto;
  height: 90px;
  width: 98%;
}
.legendwrapper {
  width: 100%;
  max-height: 100px;
  overflow-x: none;
  overflow-y: auto;
}

.legenddiv {
  height: 100%;
}

.ant-tooltip-inner {
  background-color: #359ab9;
}
.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  border-bottom-color: #359ab9;
  border-left-color: #359ab9;
}
.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  border-right-color: #359ab9;
  border-bottom-color: #359ab9;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  border-top-color: #359ab9;
  border-left-color: #359ab9;
}

.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  border-top-color: #359ab9;
  border-right-color: #359ab9;
}

@media (max-width: 480px) {
  html,
  body {
    max-width: 100vw;
    overflow-x: hidden;
  }
  .indicators .indicator_item {
    flex-basis: 100%;
  }
}

.row-container {
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  overflow: hidden;
}

.second-row {
  flex-grow: 1;
  border: none;
  margin: 0;
  padding: 0;
  margin-right: -20px;
}
